// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chat-admin-js": () => import("./../src/pages/chat/admin.js" /* webpackChunkName: "component---src-pages-chat-admin-js" */),
  "component---src-pages-chat-index-js": () => import("./../src/pages/chat/index.js" /* webpackChunkName: "component---src-pages-chat-index-js" */),
  "component---src-pages-chat-info-js": () => import("./../src/pages/chat/info.js" /* webpackChunkName: "component---src-pages-chat-info-js" */),
  "component---src-pages-chat-landing-js": () => import("./../src/pages/chat/landing.js" /* webpackChunkName: "component---src-pages-chat-landing-js" */),
  "component---src-pages-chat-login-js": () => import("./../src/pages/chat/login.js" /* webpackChunkName: "component---src-pages-chat-login-js" */),
  "component---src-pages-chat-register-js": () => import("./../src/pages/chat/register.js" /* webpackChunkName: "component---src-pages-chat-register-js" */),
  "component---src-pages-comment-js": () => import("./../src/pages/comment.js" /* webpackChunkName: "component---src-pages-comment-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-js": () => import("./../src/pages/COVID-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qanda-what-is-christianity-js": () => import("./../src/pages/qanda/what_is_christianity.js" /* webpackChunkName: "component---src-pages-qanda-what-is-christianity-js" */),
  "component---src-pages-resources-books-js": () => import("./../src/pages/resources/books.js" /* webpackChunkName: "component---src-pages-resources-books-js" */),
  "component---src-pages-resources-churches-js": () => import("./../src/pages/resources/churches.js" /* webpackChunkName: "component---src-pages-resources-churches-js" */),
  "component---src-pages-resources-devos-js": () => import("./../src/pages/resources/devos.js" /* webpackChunkName: "component---src-pages-resources-devos-js" */),
  "component---src-pages-resources-handsani-js": () => import("./../src/pages/resources/handsani.js" /* webpackChunkName: "component---src-pages-resources-handsani-js" */),
  "component---src-pages-resources-podcasts-js": () => import("./../src/pages/resources/podcasts.js" /* webpackChunkName: "component---src-pages-resources-podcasts-js" */),
  "component---src-pages-resources-sermons-js": () => import("./../src/pages/resources/sermons.js" /* webpackChunkName: "component---src-pages-resources-sermons-js" */),
  "component---src-pages-resources-songs-js": () => import("./../src/pages/resources/songs.js" /* webpackChunkName: "component---src-pages-resources-songs-js" */),
  "component---src-pages-resources-zoomevents-js": () => import("./../src/pages/resources/zoomevents.js" /* webpackChunkName: "component---src-pages-resources-zoomevents-js" */),
  "component---src-pages-resources-zoomevents-learntopray-js": () => import("./../src/pages/resources/zoomevents/learntopray.js" /* webpackChunkName: "component---src-pages-resources-zoomevents-learntopray-js" */),
  "component---src-pages-resources-zoomevents-zoommeet-js": () => import("./../src/pages/resources/zoomevents/zoommeet.js" /* webpackChunkName: "component---src-pages-resources-zoomevents-zoommeet-js" */),
  "component---src-pages-sp-js": () => import("./../src/pages/sp.js" /* webpackChunkName: "component---src-pages-sp-js" */)
}

